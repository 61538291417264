import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {deletePetition, getPetitions, groupStatusUpdate} from '../../actions/petitions'
import styles from './styles.module.scss'
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed';
import { AddEditModal } from './Components'
import { IoMdClose } from 'react-icons/io';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { getYearsArr } from '../../utils/yunarmyStat';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import Modal from 'react-responsive-modal'
import { PETITION_STATUSES } from '../../utils/const';
import { useFormFilters } from '../../hooks'




const List = () => {
  
  const dispatch = useDispatch();
  const history = useHistory();
  const {list: data, loading} = useSelector(state => state.petitions)
  const { region_departments } = useSelector(state => state.departments)
  const { nrsh, agsh, superuser } = useSelector(state => state.auth.user)

  const [modalOpen, setModalOpen] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])

  const { filters, updateFilter, clearFilter } = useFormFilters();


  useEffect(() => {
    dispatch(getPetitions(filters))
  }, [filters])
  
  const haveRights = nrsh || agsh || superuser

  const setModal = (state) => () => setModalOpen(state)
  
  const deleteItemHandler = (petId) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(deletePetition(petId, filters))
  }

  const goToItemHandler = (petId) => (e) => {
    history.push(`/petitions/${petId}`)
  }

  const setFiltersHandler = (e) => {
    const {name, value} = e.target
    updateFilter(name, value)
  }

  const selectClickHandler = (petId) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedIds(prev => prev.includes(petId) ? prev.filter(id => id !== petId) : [...prev, petId])
  }

  const selectAllClick = (e) => {
    e.preventDefault()
    setSelectedIds(allSelected ? [] : data.map(({id}) => id))
  }

  const allSelected = selectedIds.length === data?.length

  const renderRow = (item) => {
    return (
      <tr key={item.id} onClick={goToItemHandler(item.id)}>
        <td>
          <span>
            {item.region_name}
          </span>
        </td>
        <td>{item.profiles_count || 0}</td>
        <td>{item.status}</td>
        <td onClick={selectClickHandler(item.id)}>
          <CustomCheckbox color='orange' value={selectedIds.includes(item.id)} />
        </td>
        <td onClick={deleteItemHandler(item.id)}>
          <IoMdClose/>
        </td>
      </tr>
    )
  }

  const getOptions = (list) => list?.map(({id, name}) => ({value: id, name: name})) || []
  const years = getYearsArr(2019);

  const depOptions = [{name: 'Все', value: ''}, ...getOptions(region_departments)]
  const yearsOptions = [{name: 'Все', value: ''}, ...years.map(year => ({value: year, name: year}))]

  if (!haveRights) return <h1 style={{color: '#851818', marginTop: '5rem'}}>Недостаточно прав</h1>

  return (
    <div className={styles.container}>
        <div className={styles.title}>Реестр ходатайств</div>
        <div className={styles.newsList}>
          <div className={styles.newsListHeader}>
            <div>
              <CustomSelect
                title='Региональное отделение'
                options={depOptions}
                selectedValue={filters.region_department_id}
                onChange={setFiltersHandler}
                name='region_department_id'
                withoutFirst
                />
              <CustomSelect
                title='Год'
                selwidth='fit-content'
                options={yearsOptions}
                selectedValue={filters.year}
                onChange={setFiltersHandler}
                name='year'
                withoutFirst
              />
            </div>
            <div>
              <ButtonRed onClick={setModal(true)}>Добавить</ButtonRed>
              {
                selectedIds.length > 0 &&
                  <>
                    <StatusButton
                      reports_list={selectedIds}
                      filters={filters}
                      clearSelectedIds={() => setSelectedIds([])}
                    />
                    <ButtonRed>PDF</ButtonRed>
                  </>
              }
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>Отделение</th>
                <th>Кол-во претендентов</th>
                <th>Статус</th>
                <th onClick={selectAllClick}>
                  <CustomCheckbox value={allSelected} />
                </th>
                <th/>
              </tr>
            </thead>
            <tbody>
              {data?.map(renderRow)}
            </tbody>
          </table>

        </div>
        <AddEditModal
          open={modalOpen}
          closeModal={setModal(false)}
          addMode={true}
          data={{}}
        />
      </div>
  )
}

export default List



const StatusButton = ({ reports_list, filters, clearSelectedIds }) => {
  
  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)


  const selectStatusHandler = (status) => async () => {
    setLoading(true)
    const payload = {status, reports_list}
    await dispatch(groupStatusUpdate(payload, filters))
    clearSelectedIds()
    setLoading(false) 
    setModal(false)
  }

  return (
    <>
      <ButtonRed onClick={() => setModal(true)}>Изменить статус</ButtonRed>
      <Modal
        open={modal}
        onClose={() => setModal(false)}
        showCloseIcon={false}
        classNames={{modal: styles.statusModal}}
      >
        <ul>
          {PETITION_STATUSES.map(status =>
            <li key={status} onClick={selectStatusHandler(status)}>{status}</li>
          )}
        </ul>
      </Modal>
    </>
  )
}
import * as types from '../actions/types';

const initialState = {
    list: null,
    current: null,
    filters: {},
    loading : false,
  };

export default function petitions (state = initialState, action) {
  switch (action.type) {
      
    case types.SET_PETITIONS_LIST:
      return {
        ...state,
        list: action.payload
      };
    case types.SET_PETITIONS_CURRENT:
      return {
        ...state,
        current: action.payload
      };
    case types.SET_PETITIONS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
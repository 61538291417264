import storage from 'redux-persist/es/storage';
import { persistCombineReducers } from 'redux-persist';
import auth from './auth';
import schedule from './schedule';
import layout from './layout'
import users from './users'
import tourism from './tourism'
import equipment from './equipment'
import videos from './videos'
import documents from './documents'
import materialGroups from './materialGroups';
import yunarmians from './yunarmiyans';
import digital from './digital';
import task from './task'
import importProfiles from './import';
import { reducer as form } from 'redux-form';
import departments from './departments';
import quests from './quests';
import yunarmy_stat from './yunarmyStat';
import region_rating from './regionRating';
import reports from './reports';
import about from './about';
import regulation from './regulation';
import yunarmy_houses from './yunarmyHouses';
import private_stream from './private';
import badges from './badges';
import awards from './awards';
import hero from './hero';
import roles from './roles';
import chiefs_schedule from './chiefs_schedule';
import virtual_awards from './virtualAwards';
import number_reports from './number_reports';
import petitions from './petitions';


const storageConfig = { whitelist: 'auth', key: 'storage', storage };

export default persistCombineReducers(storageConfig, {
  auth,
  petitions,
  schedule,
  layout,
  users,
  materialGroups,
  documents,
  tourism,
  equipment,
  yunarmians,
  videos,
  form,
  task,
  importProfiles,
  chiefs_schedule,
  departments,
  digital,
  quests,
  yunarmy_stat,
  region_rating,
  reports,
  badges,
  about,
  regulation,
  yunarmy_houses,
  private_stream,
  awards,
  roles,
  hero,
  virtual_awards,
  number_reports
});

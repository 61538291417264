import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createPetition, updatePetition } from '../../../actions/petitions'
import { getAwardsObject } from '../../../actions/awards'
import { getDepartments } from '../../../actions/departments'
import Modal from 'react-responsive-modal'
import CustomSelect from '../../../components/CustomSelect/CustomSelect'
import { ButtonRed } from '../../../assets/common/ButtonRed/ButtonRed'
import styles from '../styles.module.scss'
import { BACKEND_DOMAIN } from '../../../utils/const'
import FileLabel from '../../../components/FileLabel/FileLabel'
import * as Yup from 'yup'


const INITIAL_VALUES = { region_department_id: '', award_id: '', status: '', files: [] }

const validationSchema = Yup.object().shape({
  region_department_id: Yup.string().required("поле является обязательным"),
  award_id: Yup.string().required("поле является обязательным"),
})

const AddEditModal = ({ open, closeModal, addMode, data }) => {
  
  const dispatch = useDispatch()
  const history = useHistory()

  const { region_departments } = useSelector(state => state.departments)
  const { data: awards } = useSelector(state => state.awards)

  useEffect(() => {
    dispatch(getDepartments())
    dispatch(getAwardsObject())
  }, [])

  useEffect(() => {
    if (!open) return;
    setValues(addMode ? INITIAL_VALUES : data)
  }, [open])
  
  
  const createPetitionAction = async (values) => {
    const petId = await dispatch(createPetition(values))
    petId && history.push(`/petitions/${petId}`)
  }
  
  const updatePetitionAction = async ({id, questions, ...values}) => {
    await dispatch(updatePetition(id, values))
    closeModal()
  }
  
  const {
    values,
    errors,
    touched,
    handleBlur,
    setValues,
    setFieldValue,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit: addMode ? createPetitionAction : updatePetitionAction
  })
  
  const modalTitle = addMode ? 'Добавление ходатайства' : 'Редактирование ходатайства'

  const getOptions = (list) =>  [{value: '', name: 'Выбрать'}, ...(list?.map(({id, name}) => ({value: id, name: name})) || [])] 

  const renderFile = (file, index) => {
    const added = !file.id
    const fileLink = added ? URL.createObjectURL(file) : `${BACKEND_DOMAIN || ''}/${file.path}/${file.name}`
    const imageMode = added ? file.type.includes('image') : file.file_type.includes('image')
    const pdfMode = added ? file.type.includes('pdf') : file.file_type.includes('pdf')
    return (
      <FileLabel
        fileName={file.name}
        fileLink={fileLink}
        imageMode={imageMode}
        pdfMode={pdfMode}
        onDelete={deleteFileHandler(index)}
      />
    )
  }

  const addFilesHandler = (e) => {
    const files = Object.values(e.target.files)
    setFieldValue('files', [...values.files, ...files])
  }

  const deleteFileHandler = (index) => () => {
    const newFilesList = values.files.filter((it, i) => i !== index)
    setFieldValue('files', newFilesList)
  }

  const onAddFilesClick = () => {
    const input = document.getElementById('inputFiles')
    input.click()
  }

  const error = (fieldName) => touched[fieldName] && errors[fieldName]

  return (
    <Modal
      open={open}
      onClose={closeModal}
      showCloseIcon={false}
      classNames={{ modal: styles.addEditModal }}
    >
      <form onSubmit={handleSubmit}>
        <h1>{modalTitle}</h1>
        <CustomSelect
          title='Выберите региональное отделение'
          options={getOptions(region_departments)}
          selectedValue={values.region_department_id}
          name='region_department_id'
          onChange={handleChange}
          validate={error('region_department_id')}
          withoutFirst
          />
        <CustomSelect
          title='Выберите награду'
          options={getOptions(awards)}
          selectedValue={values.award_id}
          name='award_id'
          onChange={handleChange}
          validate={error('award_id')}
          withoutFirst
        />
        <div className={styles.files}>
          <p>Файлы:</p>
          <div>{values.files.map(renderFile)}</div>
          <input multiple hidden id='inputFiles' type='file' onChange={addFilesHandler}/>
          <ButtonRed type='button' onClick={onAddFilesClick}>Добавить</ButtonRed>
        </div>
        <div>
          <ButtonRed type='submit'>{addMode ? 'Добавить' : 'Сохранить'}</ButtonRed>
          <ButtonRed type='button' onClick={closeModal}>Закрыть</ButtonRed>
        </div>
      </form>
    </Modal>
  )
}

export default AddEditModal
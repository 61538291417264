import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { objFilter } from '../utils/smallHelpers';

export function useFormFilters(initialState = {}) {
  
  const parseUrlParams = useCallback((search) => {
    const params = new URLSearchParams(search);
    const values = {};
    params.forEach((value, key) => {
      values[key] = value || initialState[key];
    });
    return values;
  }, [initialState]);
  

  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = useState(parseUrlParams(location.search) || {});


  useEffect(() => {
    setFilters(parseUrlParams(location.search))
  }, [location.search])
  

  const updateFilter = useCallback((key, value) => {
    const newFilters = objFilter({...filters, [key]: value })
    setFilters(newFilters);
    const params = new URLSearchParams(newFilters);
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    history.replace(newUrl)
  }, [filters, location.search]);


  const resetFilters = useCallback(() => {
    setFilters({});
    history.replace(window.location.pathname);
  }, []);


  return { filters, updateFilter, resetFilters };
}

import React, {useEffect, useState} from "react";
import Modal from "react-responsive-modal";
import {ButtonRed} from "../../assets/common/ButtonRed/ButtonRed";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import {InputFile} from "../../assets/common/InputFile/InputFile";
import Loader from "../../components/Loader/Loader";
import styles from "./Digital.module.css";
import ReactQuill from "react-quill";
import {BACKEND_DOMAIN, BACKEND_URL_PREFIX} from "../../utils/const";
import {IoMdCreate, IoMdClose} from "react-icons/io";
import previewImage from "../../assets/images/preview.png"
import {createNotification} from "../../components/Notifications/Notifications";
import moment from "moment/moment";
import Chunk from "./Chunk";

const DigitalModal = ({
    type,
    numberPage,
    selectedItem,
    setSelectedItem,
    editDigitalItem,
    addDigitalItem,
    getDigitalItems,
    data,
    loading,
    getCategory,
    accessRights,
    category,
    uploaBigdFile,
    token,
    setText,
    getUpdate,
    ...props
}) => {

    const updateRights = type === "ADD" || accessRights.ac_update

    const [item,
        setItem] = useState(null);
    const [confirmation,
        setConfirmation] = useState(null);
    const [categoriesList,
        setCategoriesList] = useState('');
    const [categories,
        setCategories] = useState(null);
    const [isCheck,
        setIsCheck] = useState(null);
    const [existingFile,
        setExistingFile] = useState(null)
    const [addFile,
        setAddFile] = useState(null)
    const [preview,
        setPreview] = useState(null)

    useEffect(() => {
        if (type === "ADD") {
            setItem({name: "", description: "", length: "", category: ''});
            setIsCheck(null)
            setCategoriesList('');
            category && setCategoriesList(prev => ([...category]));
        } else {
            if (selectedItem) {
                setItem(prev => ({
                    ...selectedItem,
                    ...prev
                }));
                const file = selectedItem.imageurl
                const fileNameArr = file
                    ?.split('/')
                const data = file
                    ? {
                        video: file,
                        fileName: fileNameArr.pop(),
                        preview: selectedItem.preview
                    }
                    : null
                setExistingFile(data)
            }
        }
    }, [selectedItem, type]);

    useEffect(() => {
        setIsCheck(null);
        getCategory(numberPage + 1);
    }, [selectedItem
            ?.category])

    useEffect(() => {
        selectedItem && selectedItem.category && setIsCheck(selectedItem.category);
        category && setCategoriesList(prev => ([...category]));
    }, [category])

    const addVideo = () => {

        const htmlObject = document.createElement('div');
        htmlObject.innerHTML = item.description;

        if (item.name.trim().length == 0) {
            createNotification("error", `Необходимо заполнить "Название"`);
        } else if (htmlObject
            ?.textContent == "") {
            createNotification("error", `Необходимо заполнить "Описание"`);
        } else if (!isCheck) {
            createNotification("error", `Необходимо выбрать категорию"`);
        } else {
            addDigitalItem({
                ...item,
                category: isCheck,
                description: htmlObject
                    ?.textContent
            }, numberPage + 1, addFile, preview);
            setCategories(null);
            setCategoriesList(null);
            setIsCheck(null)
            props.onClose();
            setText('');
        }
    };

    const editVideo = () => {

        const htmlObject = document.createElement('div');
        htmlObject.innerHTML = item.description;

        if (item.name.trim().length == 0) {
            createNotification("error", `Необходимо заполнить "Название"`);
        } else if (htmlObject
            ?.textContent == "") {
            createNotification("error", `Необходимо заполнить "Описание"`);
        } else if (!isCheck) {
            createNotification("error", `Необходимо выбрать категорию"`);
        } else {
            const incomingFile = selectedItem.imageurl
            const incomingFilePreview = selectedItem.preview
            const removeFile = incomingFile && !existingFile
            const removePreview = existingFile && !existingFile.preview && incomingFilePreview

            // uploader.methods.uploadStoredFiles()

            editDigitalItem({
                ...item,
                category: isCheck
            }, numberPage + 1, removeFile, addFile, preview, removePreview)
            setCategories(null);
            setCategoriesList(null);
            setIsCheck(null);
            props.onClose();
            setText('');
        }
    };

    const downloadFile = () => {
        if (existingFile.video) {
            const link = document.createElement('a')
            link.setAttribute('href', `${BACKEND_DOMAIN || window.location.origin}/${existingFile.video}`);
            link.setAttribute('target', '_blank');
            link.setAttribute('download', 'download');
            link.click()
        }
    }

    const handleSelectFile = (e) => {
        const file = e.target.files[0]
        const name = file.name
        setAddFile({file, name})
        setExistingFile(null)
        validateFile(file)
    };

    const handleAdd = () => {
        if (categories.length > 0 && categories.replace(/\s/g, '')) {
            setCategoriesList(prev => ([
                ...prev, {
                    category: categories
                }
            ]));
            setCategories('');
        } else {}
    }

    const validateFile = (file) => {

        var video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
            window
                .URL
                .revokeObjectURL(video.src);
            const formattedTime = moment
                .utc(video.duration * 1000)
                .format('HH:mm:ss');
            setItem(prev => ({
                ...prev,
                length: formattedTime
            }))
        }

        video.src = URL.createObjectURL(file);
    }

    const handleDeleteFile = (e) => {
        const name = existingFile
            ?.fileName || addFile.name
        e.stopPropagation()
        setConfirmation(name);
    };

    const acceptConfirmation = (name) => {
        setConfirmation(null)
        setExistingFile(null)
        setAddFile(null)
        setItem(prev => ({
            ...prev,
            length: null
        }))
    };

    const rejectConfirmation = () => {
        setConfirmation(null);
    };

    const handleSelectPreview = (e) => {
        e.stopPropagation()
        const input = document.getElementById('inputPreview')
        input.click()
    }

    const handleSetPreview = (e) => {
        const file = e.target.files[0]
        setPreview(file)
        existingFile && setExistingFile(prev => ({
            ...prev,
            preview: null
        }))
    }

    const clearPreview = (e) => {
        e.stopPropagation()
        setPreview(null)
        existingFile && setExistingFile(prev => ({
            ...prev,
            preview: null
        }))
    }

    return (
        <Modal
            {...props}
            classNames={{
            modal: styles.modal,
            closeButton: styles.closeBtn
        }}>
            {confirmation && (<Confirmation
                top="30px"
                left="40px"
                confirmation={confirmation}
                acceptConfirmation={acceptConfirmation}
                rejectConfirmation={rejectConfirmation}/>)}
            {loading && <Loader top="40%" left="40%"/>}
            <div className={styles.title}>
                {type === "ADD"
                    ? "Добавление документа"
                    : "Редактирование документа"}
            </div>
            {item && <div className={styles.modalBody}>

                <div>
                    Название:
                    <input
                        value={item
                        ?.name}
                        onChange={(e) => setItem((prev) => ({
                        ...prev,
                        name: e.target.value
                    }))}
                        disabled={!updateRights}
                        className={styles.input}
                        placeholder="Название"
                        style={{
                        marginBottom: "10px"
                    }}/>
                    <span className={styles.descr}>Описание:</span>

                    <ReactQuill
                        value={item
                        ?.description
                            ? item
                                ?.description
                                : ''}
                        readOnly={!updateRights}
                        onChange={(e) => setItem((prev) => ({
                        ...prev,
                        description: e
                    }))}/>
                    <span className={styles.categoriesTitleMain}>
                        Порядковый номер документа:
                    </span>
                    <input
                        className={styles.input}
                        style={{
                        width: "35px"
                    }}
                        type="number"
                        disabled={!updateRights}
                        value={item.serial_number}
                        onChange={(e) => setItem(prev => ({
                        ...prev,
                        serial_number: e.target.value
                    }))}/>
                    <div className={styles.categoriesWrpapper}>
                        <span className={styles.categoriesTitleMain}>
                            Категории:
                        </span>
                        <div className={styles.wrapperInput}>
                            <input
                                type="text"
                                value={categories}
                                disabled={!updateRights}
                                onChange={(e) => setCategories(e.target.value)}/> {categories && <div className={styles.buttonAdd} onClick={handleAdd}>
                                Добавить
                            </div>}
                        </div>
                        <div className={styles.categoriesLists}>

                            {categoriesList && categoriesList.map(item => <div key={`id_${item.id}`}>
                                <div className={styles.radio} onClick={() => setIsCheck(item.category)}>
                                    <input
                                        className={styles.customRadio}
                                        type="radio"
                                        checked={item.category == isCheck}
                                        value={item.category || undefined}/>
                                    <label>{item.category == null
                                            ? 'Не выбрано'
                                            : item.category}</label>
                                </div>
                            </div>)}
                        </div>
                    </div>
                    {!(existingFile
                        ?.fileName || addFile) && <div>
                            Ссылка на файл:
                            <input
                                value={item
                                ?.link}
                                disabled={!updateRights}
                                onChange={(e) => setItem((prev) => ({
                                ...prev,
                                link: e.target.value
                            }))}
                                className={styles.input}
                                placeholder="Ссылка на файл"
                                style={{
                                marginBottom: "10px"
                            }}/>
                        </div>}

                    {(existingFile
                        ?.fileName || addFile) && <div className={styles.fileBlock}>
                            <p>Файл:</p>
                            <input
                                type="file"
                                id="inputPreview"
                                disabled={!updateRights}
                                accept="image/png, image/jpeg"
                                onChange={handleSetPreview}
                                hidden/>
                            <div className={styles.fileItem} onClick={downloadFile}>
                                <IoMdCreate onClick={handleSelectPreview} title='Изменить превью'/>
                                <IoMdClose onClick={handleDeleteFile} title='Удалить видео'/>
                                <div className={styles.filePreview}>
                                    <img
                                        src={(existingFile && existingFile.preview)
                                        ? `${BACKEND_DOMAIN || window.location.origin}/${existingFile.preview.path}/${existingFile.preview.name}`
                                        : (addFile && addFile.preview)
                                            ? addFile.preview
                                            : preview
                                                ? URL.createObjectURL(preview)
                                                : previewImage}
                                        alt="o_O"/> {(existingFile
                                        ?.preview || preview) && <button onClick={clearPreview}>
                                            Очистить
                                        </button>}
                                </div>
                                <div className={styles.fileTitle}>{existingFile
                                        ? existingFile.fileName
                                        : addFile.name}</div>
                            </div>
                        </div>}

                    {type === "EDIT" && !item.link && !(existingFile
                        ?.fileName || addFile)
                        ? <Chunk
                                getUpdate={() => getUpdate(item.id)}
                                token={token}
                                endpoint={`${BACKEND_URL_PREFIX}/digital_academy/${item.id}/video`}/>

                        : ''}
                </div>
                <div className={styles.modalButtons}>
                    {updateRights && <ButtonRed
                        onClick={type === "ADD"
                        ? addVideo
                        : editVideo}>
                        {type === "ADD"
                            ? "Добавить"
                            : "Сохранить"}
                    </ButtonRed>}
                    <ButtonRed onClick={props.onClose}>Закрыть</ButtonRed>
                </div>

            </div>}
        </Modal>
    );
};

export default DigitalModal;
import moment from "moment";

import { BACKEND_URL_PREFIX } from "../utils/const";
import {
  FETCHED_NEWSLIST,
  FETCHED_NEWS,
  FETCH_NEWSPAGES,
  FETCH_AUTHORS,
  EDITED_NEWS,
  UPDATE_SEND_REQUEST,
  SET_CURRENT_PAGE,
  SET_EVENT_QUESTIONS,
  SET_CURRENT_EVENT,
  SET_EVENT_REQUESTS,
  SET_EVENT_STAGES,
  SET_QUESTION_ANSWERS,
  SET_SCHEDULE_LOADING,
  SET_LIST_FOR_EXCEL,
  SET_EVENT_FILES_LIST,
  SET_LOADING_BUT,
  SET_EVENT_QUESTIONS_DEF,
  ERROR,
  SET_DETACHMENTS_NEWS,
  SET_CURRENT_NEW,
  SET_ALL_EVENTS,
  SAVE_AND_CLICK,
  SET_CATEGORIES,
  SET_ANIMATIONS_LIST,
  SET_EVENT_STATISTIC,
  SET_MANUAL_PROFILES,
  SET_MANUAL_ANSWERS
} from "./types";
import { buildDefaultHeaders } from "./helper";
import { PATHS } from "../routing/Routes";
import { checkAndUpdateToken, signOut } from "./auth";
import { createNotification } from "../components/Notifications/Notifications";




export const setNumberPage = (number) => ({type: SET_CURRENT_PAGE, payload: number})
export const setCurrentEvent = (number) => ({type: SET_CURRENT_EVENT, payload: number})
export const saveAndClick = (type) => ({type: SAVE_AND_CLICK, payload: type})
export const removeCategories = () => ({type: SET_CATEGORIES, payload: null})


export const getNews = (articletype, history, filter, offset, author, level, department_id, region_id, textSearch, sort) =>
  async (dispatch, getState) => {

    dispatch({type: SET_SCHEDULE_LOADING, payload: true})
    
    const newsOnPage = getState().auth.itemsOnPage
    const newsCount = getState().schedule.currentEvent ? 999 : newsOnPage
    const status = filter && filter !== "starred" ? `status=${filter}&` : "";
    const starred = filter && filter === "starred" ? `starred=${filter === "starred"}&` : "";
    const pagination = `offset=${newsCount * ((offset || 1) - 1)}&limit=${newsCount}&`;
    const authorlast = author ? `author='${author}'&` : "";
    const levellast = level ? `level=${level}&` : "";
    const department_idlast = department_id ? `department_id=${department_id}&` : "";
    const region_idlast = region_id ? `region_id=${region_id}&` : "";
    const text = textSearch ? `text=${textSearch}&` : "";
    const alph = sort ? `alph=${sort}&` : "";
    const webRequest = 'web=true'
    
    const params = `${status}${starred}${pagination}${authorlast}${levellast}${department_idlast}${region_idlast}${text}${alph}${webRequest}`;
    
    const getUrl = (countNews) => {
      const count = countNews ? "/count" : "";
      return `${BACKEND_URL_PREFIX}/articles/${articletype}${count}?${params}`;
    };

    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      const response = await fetch(getUrl(), {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const newsList = await response.json();
        dispatch({ type: FETCHED_NEWSLIST, newsList });
        dispatch(getNewsCount(getUrl(true), history));
      } else {
        response.status === 401 && dispatch(signOut(history));
      }
    }
    dispatch({type: SET_SCHEDULE_LOADING, payload: false})
};

export const getNewsCount = (url, history) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  const newsOnPage = getState().auth.itemsOnPage
  const _newsOnPage = getState().schedule.currentEvent ? 999 : newsOnPage
  if (getState().auth.token) {
    const response = await fetch(url, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const newsCount = await response.json();
      const newsPages = Math.ceil(newsCount / _newsOnPage);
      dispatch({ type: FETCH_NEWSPAGES, newsPages });
    } else {
      response.status === 401 && dispatch(signOut(history));
    }
  }
};

export const addNewsImage =
  (file, id, history, pathname, toEvents) => async (dispatch, getState) => {
    const myNewFile = new File([file], `${moment().format()}${file.name}`, {
      type: file.type,
    });
    const formData = new FormData();
    formData.append("file", myNewFile);
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${id}/image`, {
      method: "POST",
      body: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });

    if (response.ok) {
      dispatch({ type: FETCHED_NEWS, news: null });

      let path = "";
      switch (pathname) {
        case "/event/add_event":
        case "/event/edit_event":
          path = PATHS.AUTH.EVENT;
          break;
        case "/news/add_news":
        case "/news/edit_news":
          path = PATHS.AUTH.NEWS;
          break;
        default:
          path = PATHS.AUTH.ADS;
          break;
      }

      if (toEvents == 'quest') {  
        history.push(`/event/edit_event/${id}`);
        dispatch(saveAndClick(toEvents));
      } else if (toEvents == 'files') {  
        history.push(`/event/edit_event/${id}`);
        dispatch(saveAndClick(toEvents));
      } else {
        history.push(path);
      }
    } else {
      response.status === 401 && dispatch(signOut(history));
      response.status === 413 &&
        alert(
          "Превышен размер отправляемого файла! (Максимальный размер файла 1Mb)"
        );
    }
    dispatch(loading(false));
  };

export const getCurrentNew = (id) => async (dispatch, getState) => {
  try {
    dispatch({type: SET_SCHEDULE_LOADING, payload: true})
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      const image = payload.imageurl ? payload.imageurl : null
      dispatch({ type: SET_CURRENT_NEW, payload: {...payload, image: image} });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  } catch (ERR) {

  } finally {
    dispatch({type: SET_SCHEDULE_LOADING, payload: false})
  }
}

export const removeNewsImage =
  (id, history, pathname) => async (dispatch, getState) => {
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${id}/image`, {
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });

    if (response.ok) {
      dispatch({ type: FETCHED_NEWS, news: null });
    } else {
      response.status === 401 && dispatch(signOut(history));
    }
  };

export const addNews =
  (values, history, pathname, toEvents = false, categories) => async (dispatch, getState) => {
    dispatch(loading(true));
    let img = values.image;
    delete values.image;

    values.level?.value &&  ( values.level = values.level.value )
    values.articletype?.value && ( values.articletype = values.articletype.value )
    
    if (values.level == 2 || values.level == 3) {
      values.department_id = values.department_id?.value;
      const list = values.department_list[0]?.value;
      values.department_list = list != null ? [list] : [];
    }

    const response = await fetch(`${BACKEND_URL_PREFIX}/article`, {
      method: "POST",
      body: JSON.stringify(values),
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const news = await response.json();
      dispatch({ type: FETCHED_NEWS, news });

      if (img && news) {
        dispatch(addNewsImage(img[0], news.id, history, pathname, toEvents));
      } else if (toEvents == 'quest') {
          history.push(`/event/edit_event/${news?.id}`);
          dispatch(saveAndClick(toEvents));
      } else if (toEvents == 'files') {
        history.push(`/event/edit_event/${news?.id}`);
        dispatch(saveAndClick(toEvents));
    } else {
        history.push();
        let path = "";
        switch (pathname) {
          case "/event/add_event":
            path = PATHS.AUTH.EVENT;
            break;
          case "/news/add_news":
            path = PATHS.AUTH.NEWS;
            break;
          default:
            path = PATHS.AUTH.ADS;
            break;
        }
        history.push(path);
        dispatch(loading(false));
      }
      categories && await dispatch(updateCategories(news.id, categories))
      dispatch(editedNewsUpdate(false));
    } else {
      response.status === 401 && dispatch(signOut(history));
      dispatch(loading(false));
    }
  };

export const editNews =
  (id, values, history, pathname, state, numberPage, authors, level, department_id, newCategories) =>
  async (dispatch, getState) => {
    const imageFile = values.image
    const imageFileUrl = values.imageurl
    const id = values.id
    
    delete values.files
    delete values.image
    delete values.imageurl
    delete values.likes
    delete values.department_id
    delete values.id

    if (values.department_list && (values.department_list[0] == null || values.department_list[0] == undefined)) {
      values.department_list = []; 
    }
    
    values.level?.value &&  ( values.level = values.level.value )
    values.articletype?.value && ( values.articletype = values.articletype.value )
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${id}`, {
      method: "PUT",
      body: JSON.stringify(values),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const news = await response.json();
      dispatch({ type: FETCHED_NEWS, news });

      if (!imageFile && imageFileUrl && history) {
        await dispatch(removeNewsImage(id, history, pathname));
      } else if (imageFile && typeof(imageFile)==="object") {
        await dispatch(
          addNewsImage(imageFile[0], id, history, pathname)
        );
      }
      newCategories && await dispatch(updateCategories(id, newCategories))

      if (history) {
        !imageFile && !imageFileUrl && createNotification("success", "Сохранено!");
        let path = "";
        if ((/event/i).test(pathname)) {
          path = PATHS.AUTH.EVENT;
        } else if ((/news/i).test(pathname)) {
          path = PATHS.AUTH.NEWS;
        } else {
          path = PATHS.AUTH.ADS;
        }
        history.push(path);
      } else {
        await dispatch(
          getNews(values.articletype, null, state, numberPage, authors, level, department_id)
        );
      }
      dispatch(editedNewsUpdate(false));
    } else {
      response.status === 401 && dispatch(signOut(history));
    }
  };

  export const deleteNews = (id, articletype, history, filter, offset, author, level, department_id, region_id, textSearch, sort) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/article/${id}`, {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      });
  
      if (response.ok) {
        dispatch(getNews(articletype, history, filter, offset, author, level, department_id, region_id, textSearch, sort));
        createNotification('success', 'Успешно!')
      } else {
        createNotification('error', 'Ошибка!')
      }
    }
  };

export const getAuthors = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/user`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const authors = await response.json();
      dispatch({ type: FETCH_AUTHORS, authors });
    } else {
        dispatch({ type: ERROR, payload: response });
    }
  }
};

export const editedNewsUpdate = (editedNews) => ({
  type: EDITED_NEWS,
  editedNews,
});

const loading = (sendRequest) => (dispatch) =>
  setTimeout(() => dispatch({ type: UPDATE_SEND_REQUEST, sendRequest }), 1000);

export const downloadArchive = (history) => async (dispatch) => {
  const response = await fetch(`${BACKEND_URL_PREFIX}/zip`, {
    method: "GET",
  });

  if (response.ok) {
    window.open(`${window.location.host}/kiosk.zip`);
  } else {
    dispatch({ type: ERROR, payload: response });
    response.status === 401 && dispatch(signOut(history));
  }
};


export const getEventQuestions = (id) => async (dispatch, getState) => {
  dispatch({type: SET_SCHEDULE_LOADING, payload: true})
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/article_stage/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_EVENT_QUESTIONS, payload: data });
      dispatch({ type: SET_EVENT_QUESTIONS_DEF, payload: data });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
  dispatch({type: SET_SCHEDULE_LOADING, payload: false})
};

export const editEventQuestions = (id, qid, stageId, value, images=null) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    
    const payload = {...value}

    const removeImages = [...value?.removeImages]
    const questionImage = value.question_file && value.question_file
    const questionAnswers = value.answers
        
    delete payload.removeImages
    delete payload.question_file
    delete payload.answers


    value.counting_method = `${value.type === 'choice' ? 'auto' : 'manual'}`;
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${id}/questions/${qid}`, {
      method: "PUT",
      body: JSON.stringify(payload),
      ...buildDefaultHeaders(getState),
    });
    
    if (response.ok) {

      if (images && images.length) {
        for (const image of images) {
          await dispatch(uploadFile(qid, image))
        }
      }

      if (removeImages.length) {
        for (const photoId of removeImages) {
          await dispatch(deleteFile(photoId))
        }
      }
      
      const initQuestion = getState().schedule.questions_list.article_stage.questions.find(q => q.id === qid)
      const respData = await response.json()
      const newOptions = respData.options
      
      initQuestion.question_file?.id && !questionImage && await dispatch(deleteQuestionImage(qid))
      questionImage && !questionImage.id && await dispatch(addQuestionImage(qid, questionImage))

      if (questionAnswers) {
        for (let i = 0; i < questionAnswers.length; i++) {
          if (questionAnswers[i]) {
            initQuestion.answers[i]?.answer_file && !questionAnswers[i].answer_file && await dispatch(deleteQuestionAnswerImage(qid, newOptions[i].id))
            questionAnswers[i].answer_file && !questionAnswers[i].answer_file.id && await dispatch(addQuestionAnswerImage(qid, newOptions[i].id, questionAnswers[i].answer_file))
          } 
        }
      }

      const answerAnimations = newOptions?.map((item, ind) => ({
            option_id: item.id,
            animation_id: value.animations[ind].animation?.animation_id || null,
            description: value.animations[ind].animation?.description || null
      }))

      const animForRemove = answerAnimations?.filter(it => {
        const initItem = initQuestion.animations.find(anim => anim.answer_id === it.option_id)
        const initId = initItem?.animation?.animation_id
        const remove = initId && initId !== it.animation_id 
        return remove
      }) || []
      
      const animForUpdate = answerAnimations?.filter(it => it.animation_id || it.description) || []

      for (const anim of animForRemove) {
        await dispatch(deleteAnswerAnimation(anim.option_id))
      }

      animForUpdate.length && await dispatch(addAnswerAnimation(animForUpdate))    
      await dispatch(getEventQuestions(stageId));

    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const addEventQuestions = (id, stageId, data, images=null) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    
    const payload = {...data}
    delete payload.option_images
    delete payload.question_images
    delete payload.animations
    payload.counting_method = `${data.type === 'choice' ? 'auto' : 'manual'}`;
    payload.stage = stageId


    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${id}/questions`, {
      method: "POST",
      body: JSON.stringify(payload),
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      
      const respData = await response.json()
      const qid = respData.id

      if (images && images.length) {
        for (const image of images) {
          await dispatch(uploadFile(id, image))
        }
      }

      data.question_image && await dispatch(addQuestionImage(qid, data.question_image))

      if (data.option_images) {
        for (let i = 0; i < data.option_images.length; i++) {
          data.option_images[i] && await dispatch(addQuestionAnswerImage(qid, respData.options[i].id, data.option_images[i])) 
        }
      }

      const animationsPayload = data.animations.reduce((accum, anim, ind, array) => {
        if (Object.keys(anim.animation).length) {
          accum.push({
            option_id: respData.options[ind].id,
            animation_id: anim.animation.animation_id || null,
            description: anim.animation.description || null
          })
        }
        return accum
      }, [])

      animationsPayload.length && await dispatch(addAnswerAnimation(animationsPayload))

      dispatch(getEventQuestions(stageId));
    } else {
      dispatch({ type: ERROR, payload: response });
    }

  }
};

export const deleteEventQuestions = (id, qid, stageId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${id}/questions/${qid}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {} else {
      dispatch({ type: ERROR, payload: response });
    }
    dispatch(getEventQuestions(stageId));
  }
};

export const getEventRequests = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${id}/requests`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_EVENT_REQUESTS, payload: data });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const editEventRequest = (id, yunId, value) => async (dispatch, getState) => {
  try {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/article/${id}/request/${yunId}`, {
        method: "PUT",
        body: JSON.stringify(value),
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        dispatch(getEventRequests(id));
      } else {
        dispatch({ type: ERROR, payload: response });
        response.status === 403 && createNotification('error', 'Недостаточно прав для изменение статуса')
      }
    }
  } catch (ERR) {
    console.log(ERR)
  }
};

export const removeStageRequest = (stageId, profileId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    
    const data = {profile_ids: [profileId]}

    const response = await fetch(`${BACKEND_URL_PREFIX}/article_stage/${stageId}/reload_stage`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      createNotification('success', 'Ответы успешно удалены')
    } else {
      dispatch({ type: ERROR, payload: response });
      createNotification('error', 'Ошибка удаления ответов')
    }
  }
};

export const getQuestionAnswers = (id, profileId) => async (dispatch, getState) => {
  dispatch({type: SET_SCHEDULE_LOADING, payload: true})
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${id}/answers/${profileId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_QUESTION_ANSWERS, payload: data });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
  dispatch({type: SET_SCHEDULE_LOADING, payload: false})
};

export const getEventStages = (evId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${evId}/article_stage`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_EVENT_STAGES, payload: data.article_stages });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const addEventStage = (evId, data) => async (dispatch, getState) => {
  let returnState
  try {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      
      const payload = {...data};
      delete payload.start_image
      delete payload.end_image

      const response = await fetch(`${BACKEND_URL_PREFIX}/article_stage`, {
        method: "POST",
        body: JSON.stringify(payload),
        ...buildDefaultHeaders(getState),
      });

      const respData = await response.json()
      
      if (response.ok) {
        
        const id = respData.article_stage.id
        
        data.start_image?.size && await dispatch(uploadStageImage(id, data.start_image, 'start_image'))
        data.end_image?.size && await dispatch(uploadStageImage(id, data.end_image, 'end_image'))
  
        dispatch(getEventStages(evId));
        returnState = true
        createNotification('success', 'Этап успешно добавлен')
      } else {
        const {errors} = respData
        createNotification('error', errors || 'Ошибка добавления этапа')
        
        dispatch({ type: ERROR, payload: response });
        returnState = false
      }
    }
  } catch (ERR) {
    returnState = false
  } finally {
    return returnState
  }
}

export const editEventStage = (stId, evId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    
    const payload = {...data}
    delete payload.start_image
    delete payload.end_image

    const response = await fetch(`${BACKEND_URL_PREFIX}/article_stage/${stId}`, {
      method: "PUT",
      body: JSON.stringify(payload),
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      
      const initStage = getState().schedule.eventStages.find(stage => stage.id === stId)

      data.start_image?.size && await dispatch(uploadStageImage(stId, data.start_image, 'start_image'))
      data.end_image?.size && await dispatch(uploadStageImage(stId, data.end_image, 'end_image'))

      initStage?.start_image && !data.start_image && await dispatch(deleteStageImage(stId, 'start_image'))
      initStage?.end_image && !data.end_image && await dispatch(deleteStageImage(stId, 'end_image'))
      
      dispatch(getEventStages(evId));
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const deleteEventStage = (stId, evId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/article_stage/${stId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      dispatch(getEventStages(evId));
    } else {
      dispatch({ type: ERROR, payload: response });
      const data = await response.json();
      if (data.error && data.error[0].includes('article_question')) {
        createNotification("error", "Ошибка удаления этапа. Этап содержит вопросы");
      } else {
        createNotification("error", "Ошибка удаления этапа");
      }
    }
  }
};




export const uploadStageImage = (stageId, file, imageType) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())

  const fd = new FormData()
  fd.append('file', file)

  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/article_stage/${stageId}/${imageType}`, {
          method: "POST",
          headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd,
      })
      if (response.ok) {
          console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const deleteStageImage = (stageId, imageType) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/article_stage/${stageId}/${imageType}`, {
      method: "DELETE",
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
        console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const uploadFile = (id, file) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())

  const fd = new FormData()
  fd.append('file', file)

  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/question/${id}/upload_image`, {
          method: "POST",
          headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd,
      })
      if (response.ok) {
          console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const deleteFile = (imageId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/question/image/${imageId}`, {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
        console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const getListForExcel = (evId) => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING_BUT, payload: true });
  dispatch({ type: SET_LIST_FOR_EXCEL, payload: null });
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${evId}/profiles_to_stages`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_LOADING_BUT, payload: false });
      dispatch({ type: SET_LIST_FOR_EXCEL, payload: data });
    } else {
        dispatch({ type: ERROR, payload: response });
    }
  }
};


export const getEventFiles = (evId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/articles/${evId}/files`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
    })
    if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_EVENT_FILES_LIST, payload: data.files });
    } else {
        dispatch({ type: ERROR, payload: response });
    }
}
}

export const getEventStat = (stageId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/article_stage/${stageId}/leaderboard
    `, {
        method: "GET",
        ...buildDefaultHeaders(getState),
    })
    if (response.ok) {
        const payload = await response.json();
        dispatch({ type: SET_EVENT_STATISTIC, payload });
    } else {
        dispatch({ type: ERROR, payload: response });
    }
  }
}

export const uploadEventFiles = (eventId, files) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())

  if (getState().auth.token) {

    for (const file of files) {
      const fd = new FormData()
      fd.append('file', file)
      const response = await fetch(`${BACKEND_URL_PREFIX}/article/${eventId}/file`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd,
        });

        if (response.ok) {
          createNotification('success', 'Успешно');
        } else {
            if (response.statusText == 'CONFLICT') {
              createNotification('error', 'Файл уже добавлен');
            }
        }
    }
    dispatch(getEventFiles(eventId))
  } 
}

export const deleteEventFile = (fileId, eventId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/article/file/${fileId}`, {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
        dispatch(getEventFiles(eventId));
        createNotification('success', 'Успешно');
      } else {
          dispatch({ type: ERROR, payload: response });
      }
  }
}

export const getDepartments = (parent = 0) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {

      const response = await fetch(`${BACKEND_URL_PREFIX}/department?parent=${parent}&detachment=${false}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({ type: SET_DETACHMENTS_NEWS, payload: data });
          } else {
            dispatch({ type: ERROR, payload: response });
          }
    }
}

export const getAllEvents = () => async (dispatch, getState) => {
  dispatch({type: SET_SCHEDULE_LOADING, payload: true})
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/articles/list/2`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_ALL_EVENTS, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
  dispatch({type: SET_SCHEDULE_LOADING, payload: false})
}

export const getCategories = (eventId) => async (dispatch, getState) => {
  dispatch({type: SET_SCHEDULE_LOADING, payload: true})
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${eventId}/questions/category`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const payload = data.categories
      dispatch({ type: SET_CATEGORIES, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
  dispatch({type: SET_SCHEDULE_LOADING, payload: false})
}

export const updateCategories = (eventId, newCategories) => async (dispatch, getState) => {
  
  
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    
    const initialCats = getState().schedule.categories
    const newCategoriesIds = newCategories.filter(it => it.id).map(it => it.id)
    
    const addedCats = newCategories.filter(it => !it.id)
    const deletedCats = initialCats?.filter(it => !newCategoriesIds.includes(it.id))
    const editedCats = newCategories.filter(it => it.id && it.changed)
    
    
    if (addedCats.length) {
      const payload = addedCats.map(cat => ({
        category: cat.category,
        description: cat.description || ''
      }))
      await dispatch(addCategoty(eventId, payload))
    }
    if (deletedCats?.length) {
      const payload = deletedCats.map(cat => cat.id)
      await dispatch(deleteCategoty(eventId, payload))
    }
    if (editedCats.length) {
      for (const cat of editedCats) {
        const catId = cat.id
        const payload = {
          category: cat.category,
          description: cat.description
        }
        await dispatch(editCategoty(eventId, catId, payload))
      }
    }
  }
}


export const addCategoty = (eventId, catsList) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const data = {categories: catsList}
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${eventId}/questions/category`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const data = await response.json()
      const payload = data.categories
      dispatch({ type: SET_CATEGORIES, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const editCategoty = (eventId, catId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${eventId}/questions/category/${catId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState)
    });

    if (response.ok) {
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const deleteCategoty = (eventId, idsList) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const data = {category_ids: idsList}
    const response = await fetch(`${BACKEND_URL_PREFIX}/article/${eventId}/questions/category`, {
      method: "DELETE",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const addQuestionImage = (id, file) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())

  const fd = new FormData()
  fd.append('file', file)

  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/question/${id}/image`, {
          method: "POST",
          headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd,
      })
      if (response.ok) {
          console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const deleteQuestionImage = (questId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/question/${questId}/image`, {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
        console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const addQuestionAnswerImage = (qid, answId, file) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())

  const fd = new FormData()
  fd.append('file', file)

  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/question/${qid}/option/${answId}/image`, {
          method: "POST",
          headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd,
      })
      if (response.ok) {
          console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const deleteQuestionAnswerImage = (qid, answId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/question/${qid}/option/${answId}/image`, {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
        console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const getAnimations = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/animations`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const payload = data.animations
      dispatch({ type: SET_ANIMATIONS_LIST, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const addAnswerAnimation = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())

  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/option_to_animations`, {
          method: "POST",
          body: JSON.stringify(data),
      ...buildDefaultHeaders(getState)
      })
      if (response.ok) {
          console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const deleteAnswerAnimation = (optionId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/option_to_animations/option/${optionId}`, {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
        console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const updateAnswerAnimation = (otoaId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/option_to_animations/${otoaId}`, {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState)
      })
      if (response.ok) {
        console.log("ok")
      } else {
        dispatch({ type: ERROR, payload: response });
      }
  }
}

export const getManualProfiles = (stageId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_SCHEDULE_LOADING, payload: true });
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/manual_answers/stage/${stageId}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const payload = await response.json();
        dispatch({ type: SET_MANUAL_PROFILES, payload });
      } else {
        dispatch({ type: SET_MANUAL_PROFILES, payload: null });
      }
    }
  } catch (ERR) {
    dispatch({ type: SET_MANUAL_PROFILES, payload: null });
  } finally {
    dispatch({ type: SET_SCHEDULE_LOADING, payload: false });
  }
};

export const getManualAnswers = (stageId, profileId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_SCHEDULE_LOADING, payload: true });
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/manual_answers/stage/${stageId}/profile/${profileId}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const payload = await response.json();
        dispatch({ type: SET_MANUAL_ANSWERS, payload });
      } else {
        dispatch({ type: SET_MANUAL_ANSWERS, payload: null });
      }
    }
  } catch (ERR) {
    dispatch({ type: SET_MANUAL_ANSWERS, payload: null });
  } finally {
    dispatch({ type: SET_SCHEDULE_LOADING, payload: false });
  }
};

export const updateManualAnswerStatus = (resultId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/manual_answers/${resultId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState)
    })
    if (response.ok) {
      createNotification('success', 'Статус успешно изменен')
    } else {
      createNotification('error', 'Ошибка изменения статуса')
      dispatch({ type: ERROR, payload: response });
    }
  }
}
export const SIGNED_OUT = 'SIGNED_OUT';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_COMPLETED = 'AUTH_COMPLETED';
export const SSO_IS_NOT_IMPLEMENTED = 'SSO_IS_NOT_IMPLEMENTED';

export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_REGIONS_AUTH = 'SET_USER_REGIONS_AUTH';
export const RESET_USER_INFO = 'RESET_USER_INFO';
export const SET_USER_PHOTO_LOADING = 'SET_USER_PHOTO_LOADING'
export const SET_REGION_LIST_AUTH = 'SET_REGION_LIST_AUTH'
export const SET_USER_ACCESS_RIGHTS = 'SET_USER_ACCESS_RIGHTS'

export const SET_DASHBOARD_INFO = 'SET_DASHBOARD_INFO'
export const SET_DASHBOARD_INFO_LOADING = 'SET_DASHBOARD_INFO_LOADING'

export const NETWORK_REQUEST_SENT = 'NETWORK_REQUEST_SENT';

export const LAYOUT_FETCH_COMPLETED = 'LAYOUT_FETCH_COMPLETED';
export const LINKS_FETCH_COMPLETED = 'LINKS_FETCH_COMPLETED';

export const IS_BASIC_LOGIN_ALLOWED = 'IS_BASIC_LOGIN_ALLOWED';

export const AUTH_ERROR_MODAL_CLOSED = 'AUTH_ERROR_MODAL_CLOSED';
export const SET_LOADING_BUT = 'SET_LOADING_BUT';

export const SET_REGION_RATING_IS_NEW = 'SET_REGION_RATING_IS_NEW';
export const SET_REGION_RATING_BY_ID = 'SET_REGION_RATING_BY_ID';

export const ERROR = 'ERROR';

export const SET_STEPS_DATA_LOAD = 'SET_STEPS_DATA_LOAD';
export const SAVE_AND_CLICK = 'SAVE_AND_CLICK';

export const SET_DETACHMENTS_IMG_LOAD = 'SET_DETACHMENTS_IMG_LOAD';
export const SET_DETACHMENTS_BY_REGION = 'SET_DETACHMENTS_BY_REGION';

export const SET_DEPS_LOADING = 'SET_DEPS_LOADING';
export const SET_TROOPS_LOADING = 'SET_TROOPS_LOADING';

export const SET_ACTIVITY_RATING_DATA_ALL = 'SET_ACTIVITY_RATING_DATA_ALL';
export const SET_REPORT_LOADING_ALL = 'SET_REPORT_LOADING_ALL';

export const SET_ACTIVITY_RATING_DATA_NEW = 'SET_ACTIVITY_RATING_DATA_NEW';
export const SET_REPORT_LOADING_NEW = 'SET_REPORT_LOADING_NEW';
export const SET_CHECK_STATUS = 'SET_CHECK_STATUS';
export const SET_CHECK_STATUS_LOADING = 'SET_CHECK_STATUS_LOADING';
export const SET_ACTIVITY_RATING_DATA_DOWNLOAD = 'SET_ACTIVITY_RATING_DATA_DOWNLOAD';
export const SET_ACTIVITY_RATING_DATA_DOWNLOAD_LOADING = 'SET_ACTIVITY_RATING_DATA_DOWNLOAD_LOADING';

export const FETCHED_NEWSLIST = 'FETCHED_NEWSLIST';
export const FETCH_NEWSPAGES = 'FETCH_NEWSPAGES';
export const FETCHED_NEWS = 'FETCHED_NEWS';
export const REMOVE_NEWS = 'REMOVE_NEWS';
export const FETCH_AUTHORS = 'FETCH_AUTHORS';
export const FETCHED_USER = 'FETCHED_USER';
export const EDITED_NEWS = 'EDITED_NEWS';
export const SET_CURRENT_NEW = 'SET_CURRENT_NEW';
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT'
export const SET_EVENT_REQUESTS = 'SET_EVENT_REQUESTS'
export const SET_QUESTION_ANSWERS = 'SET_QUESTION_ANSWERS'
export const SET_SCHEDULE_LOADING = 'SET_SCHEDULE_LOADING'
export const SET_EVENT_QUESTIONS = 'SET_EVENT_QUESTIONS'
export const SET_EVENT_QUESTIONS_DEF = 'SET_EVENT_QUESTIONS_DEF'
export const SET_EVENT_STAGES = 'SET_EVENT_STAGES'
export const SET_QUESTION_IMAGES = 'SET_QUESTION_IMAGES'
export const SET_LIST_FOR_EXCEL = 'SET_LIST_FOR_EXCEL'
export const SET_EVENT_FILES_LIST = 'SET_EVENT_FILES_LIST'
export const SET_EVENT_STATISTIC = 'SET_EVENT_STATISTIC'
export const SET_REGION_DEPARTMENTS_NEWS = 'SET_REGION_DEPARTMENTS_NEWS'
export const SET_LOCAL_DEPARTMENTS_NEWS = 'SET_LOCAL_DEPARTMENTS_NEWS'
export const SET_DETACHMENTS_NEWS = 'SET_DETACHMENTS_NEWS'
export const SET_ALL_EVENTS = 'SET_ALL_EVENTS'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_TROOP_TYPES = 'SET_TROOP_TYPES'
export const SET_MANUAL_PROFILES = 'SET_MANUAL_PROFILES'
export const SET_MANUAL_ANSWERS = 'SET_MANUAL_ANSWERS'


export const SET_ANIMATIONS_LIST = 'SET_ANIMATIONS_LIST'

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_ITEMS_ON_PAGE = 'SET_ITEMS_ON_PAGE'

export const UPDATE_SEND_REQUEST = 'UPDATE_SEND_REQUEST';

export const UPDATE_FILTER = 'UPDATE_FILTER';

export const SET_ACTIVITY_TYPES = 'SET_ACTIVITY_TYPES';

export const FETCH_DIRECTORIES_REQUEST = 'FETCH_DIRECTORIES_REQUEST';
export const FETCH_DIRECTORIES_SUCCESS = 'FETCH_DIRECTORIES_SUCCESS';
export const FETCH_DIRECTORIES_FAILURE = 'FETCH_DIRECTORIES_FAILURE';
export const FETCH_FILES_REQUEST = 'FETCH_FILES_REQUEST';
export const FETCH_FILES_SUCCESS = 'FETCH_FILES_SUCCESS';
export const FETCH_FILES_FAILURE = 'FETCH_FILES_FAILURE';
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';
export const GET_FILE_REQUEST = 'GET_FILE_REQUEST';
export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS';
export const GET_FILE_FAILURE = 'GET_FILE_FAILURE';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const SET_REGION_LIST = 'SET_REGION_LIST';
export const SET_REGION_GROUPS = 'SET_REGION_GROUPS';
export const SET_USER_REGIONS = 'SET_USER_REGIONS';
export const SET_USER_DEPARTMENTS = 'SET_USER_DEPARTMENTS';
export const SET_CHANGED_DEPARTMENTS = 'SET_CHANGED_DEPARTMENTS';
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_FEDERAL_DISTRICTS = 'SET_FEDERAL_DISTRICTS'
export const SET_USER_LOADING = 'SET_USER_LOADING'

export const SET_REGION_LIST_TOURISM = 'SET_REGION_LIST_TOURISM'

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const FETCH_INFOTERMINALS_REQUEST = 'FETCH_INFOTERMINALS_REQUEST';
export const FETCH_INFOTERMINALS_SUCCESS = 'FETCH_INFOTERMINALS_SUCCESS';
export const FETCH_INFOTERMINALS_FAILURE = 'FETCH_INFOTERMINALS_FAILURE';
export const REBOOT_INFOTERMINAL_FAILURE = 'REBOOT_INFOTERMINAL_FAILURE';
export const ADD_INFOTERMINAL_REQUEST = 'ADD_INFOTERMINAL_REQUEST';
export const ADD_INFOTERMINAL_SUCCESS = 'ADD_INFOTERMINAL_SUCCESS';
export const ADD_INFOTERMINAL_FAILURE = 'ADD_INFOTERMINAL_FAILURE';
export const EDIT_INFOTERMINAL_REQUEST = 'EDIT_INFOTERMINAL_REQUEST';
export const EDIT_INFOTERMINAL_SUCCESS = 'EDIT_INFOTERMINAL_SUCCESS';
export const EDIT_INFOTERMINAL_FAILURE = 'EDIT_INFOTERMINAL_FAILURE';

export const SET_MATERIAL_GROUPS = 'SET_MATERIAL_GROUPS'
export const SET_MATERIALS = 'SET_MATERIALS'
export const SET_FILES_LIST = 'SET_FILES_LIST'

export const SET_DOCUMENTS_COUNT = 'SET_DOCUMENTS_COUNT'
export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const SET_DOCTYPES = 'SET_DOCTYPES'
export const SET_DOCUMENTS_FILES_LIST = 'SET_DOCUMENTS_FILES_LIST'

export const SET_TOURISM_COUNT = 'SET_TOURISM_COUNT'
export const SET_TOURISM_DATA = 'SET_TOURISM_DATA'

export const SET_EQUIPMENT_COUNT = 'SET_EQUIPMENT_COUNT'
export const SET_EQUIPMENT_DATA = 'SET_EQUIPMENT_DATA'
export const SET_EQUIPMENT_IMAGE = 'SET_EQUIPMENT_IMAGE'
export const REMOVE_EQUIPMENT_IMAGE = 'REMOVE_EQUIPMENT_IMAGE'
export const SET_EQUIPMENT_LOADING = 'SET_EQUIPMENT_LOADING'

export const SET_AWARD_DATA = 'SET_AWARD_DATA'
export const SET_AWARD_DATA_BY_ID = 'SET_AWARD_DATA_BY_ID'
export const SET_AWARD_COUNT = 'SET_AWARD_COUNT'
export const SET_AWARD_IMAGE = 'SET_AWARD_IMAGE'
export const REMOVE_AWARD_IMAGE = 'REMOVE_AWARD_IMAGE'
export const SET_AWARD_LOADING = 'SET_AWARD_LOADING'
export const SET_AWARD_PROFILE = 'SET_AWARD_PROFILE'

export const SET_VIRTUAL_AWARDS_DATA = 'SET_VIRTUAL_AWARDS_DATA'
export const SET_VIRTUAL_AWARDS_COUNT = 'SET_VIRTUAL_AWARDS_COUNT'
export const SET_VIRTUAL_AWARDS_LOADING = 'SET_VIRTUAL_AWARDS_LOADING'

export const SET_PETITIONS_LIST = 'SET_PETITIONS_LIST'
export const SET_PETITIONS_CURRENT = 'SET_PETITIONS_CURRENT'
export const SET_PETITIONS_LOADING = 'SET_PETITIONS_LOADING'


export const SET_TASK_COUNT = 'SET_TASK_COUNT'
export const SET_TASK_DATA = 'SET_TASK_DATA'
export const SET_TASK_ARTICLES = 'SET_TASK_ARTICLES'
export const SET_TASK_PROFILES = 'SET_TASK_PROFILES'
export const SET_TASK_REALIZATION = 'SET_TASK_REALIZATION'
export const SET_TASK_LOADING = 'SET_TASK_LOADING'

export const SET_STEPS_DATA = 'SET_STEPS_DATA'
export const SET_STEPS_COUNT = 'SET_STEPS_COUNT'
export const ADD_STEPS = 'ADD_STEPS'


export const SET_VIDEOS_COUNT = 'SET_VIDEOS_COUNT'
export const SET_VIDEOS_DATA = 'SET_VIDEOS_DATA'
export const SET_VIDEO_FILES_LIST = 'SET_VIDEO_FILES_LIST'
export const SET_VIDEO_LOADING = 'SET_VIDEO_LOADING'

export const SET_DIGITAL_VIDEOS_COUNT = 'SET_DIGITAL_VIDEOS_COUNT'
export const SET_DIGITAL_VIDEOS_DATA = 'SET_DIGITAL_VIDEOS_DATA'
export const SET_DIGITAL_FILES_LIST = 'SET_DIGITAL_FILES_LIST'
export const SET_DIGITAL_LOADING = 'SET_DIGITAL_LOADING'
export const SET_DIGITAL_CATEGORY = 'SET_DIGITAL_CATEGORY'

export const SET_UNARMIANS_COUNT = 'SET_UNARMIANS_COUNT'
export const SET_UNARMIANS_DATA = 'SET_UNARMIANS_DATA'
export const SET_ALL_UNARMIANS_DATA = 'SET_ALL_UNARMIANS_DATA'
export const SET_UNARMIAN_PROFILE = 'SET_UNARMIAN_PROFILE'
export const CLEAR_UNARMIAN_PROFILE = 'CLEAR_UNARMIAN_PROFILE'
export const SET_UNARMIAN_LOADING = 'SET_UNARMIAN_LOADING'
export const SET_UNARMIAN_BACK_UPDATE = 'SET_UNARMIAN_BACK_UPDATE'
export const SET_UNARMIAN_PDF_LOADING = 'SET_UNARMIAN_PDF_LOADING'
export const SET_UNARMIAN_MODAL_LOADING = 'SET_UNARMIAN_MODAL_LOADING'
export const SET_UNARMIAN_IMAGES = 'SET_UNARMIAN_IMAGES'
export const SET_UNARMIAN_SPORT = 'SET_UNARMIAN_SPORT'
export const SET_UNARMIAN_ACTIVITY = 'SET_UNARMIAN_ACTIVITY'
export const SET_UNARMIAN_PAGE_NUMBER = 'SET_UNARMIAN_PAGE_NUMBER'
export const SET_UNARMIAN_DOCS_INFO = 'SET_UNARMIAN_DOCS_INFO'
export const SET_CUSTOM_DOCS_INFO = 'SET_CUSTOM_DOCS_INFO'
export const GET_TROOP_HISTORY = 'GET_TROOP_HISTORY'
export const GET_TROOP_LIST = 'GET_TROOP_LIST'
export const GET_DEPS_LIST = 'GET_DEPS_LIST'

export const SET_UNARMIAN_DOCS = 'SET_UNARMIAN_DOCS'
export const REMOVE_UNARMIAN_DOCS = 'REMOVE_UNARMIAN_DOCS'

export const SET_SLIDES_PHOTO = 'SET_SLIDES_PHOTO'
export const SET_UNARMIAN_NEW_ID = 'SET_UNARMIAN_NEW_ID'


export const SET_FILTERS = 'SET_FILTERS'
export const SET_FILTERED_REGIONS = 'SET_FILTERED_REGIONS'
export const REMOVE_REGIONS_FILTER = 'REMOVE_REGIONS_FILTER'
export const SET_FILTERED_LOCAL_DEP = 'SET_FILTERED_LOCAL_DEP'
export const REMOVE_LOCAL_DEP_FILTER = 'REMOVE_LOCAL_DEP_FILTER'
export const DELETE_FILTERS = 'DELETE_FILTERS'

export const SET_IMPORT_DATA = 'SET_IMPORT_DATA'
export const SET_IMPORT_LOADING = 'SET_IMPORT_LOADING'
export const CLEAR_IMPORT_DATA = 'CLEAR_IMPORT_DATA'

export const SET_IMPORTS_HISTORY = 'SET_IMPORTS_HISTORY'
export const SET_IMPORTS_BY_ID = 'SET_IMPORTS_BY_ID'
export const SET_IMPORTS_BY_ID_LOAD = 'SET_IMPORTS_BY_ID_LOAD'

export const EDIT_USER_PHOTO = 'EDIT_USER_PHOTO'

export const SET_REGION_DEPARTMENTS = 'SET_REGION_DEPARTMENTS'
export const SET_LOCAL_DEPARTMENTS = 'SET_LOCAL_DEPARTMENTS'
export const SET_DETACHMENTS = 'SET_DETACHMENTS'
export const SET_DETACHMENTS_LOAD = 'SET_DETACHMENTS_LOAD'
export const REMOVE_DEPARTMENTS = 'REMOVE_DEPARTMENTS'
export const SET_DETACHMENT_DETAILS = 'SET_DETACHMENT_DETAILS'
export const SET_DETACHMENT_DETAILS_LOAD = 'SET_DETACHMENT_DETAILS_LOAD'
export const SET_DEPARTMENTS_COUNT = 'SET_DEPARTMENTS_COUNT'
export const REMOVE_LOCAL_DEP = 'REMOVE_LOCAL_DEP'
export const SET_DEPARTMENT_FILES = 'SET_DEPARTMENT_FILES'
export const SET_DEPARTMENT_STAFF = 'SET_DEPARTMENT_STAFF'
export const SET_FROM_LIST_LOADING = 'SET_FROM_LIST_LOADING'



export const SET_QUESTS_COUNT = 'SET_QUESTS_COUNT'
export const SET_QUESTS_DATA = 'SET_QUESTS_DATA'
export const SET_QUESTS_TASKS = 'SET_QUESTS_TASKS'
export const REMOVE_QUEST_TASKS = 'REMOVE_QUEST_TASKS'

export const SET_YUNARMY_STAT = 'SET_YUNARMY_STAT'
export const SET_YUNARMY_LOADING = 'SET_YUNARMY_LOADING'

export const SET_REGION_RATING_DATA = 'SET_REGION_RATING_DATA'
export const SET_REGION_RATING_PARAMS = 'SET_REGION_RATING_PARAMS'
export const SET_REGION_RATING_CRITERION = 'SET_REGION_RATING_CRITERION'
export const SET_REGION_RATING_LOADING = 'SET_REGION_RATING_LOADING'
export const SET_ALL_REGION_RATING_LOADING = 'SET_ALL_REGION_RATING_LOADING'
export const SET_ALL_REGION_RATING_PARAMS = 'SET_ALL_REGION_RATING_PARAMS'
export const SET_REGION_RATING_CELL_LOADING = 'SET_REGION_RATING_CELL_LOADING'
export const SET_RATING_VALUE = 'SET_RATING_VALUE'
export const SET_CONFIRM_MODE_LOADING = 'SET_CONFIRM_MODE_LOADING'


export const SET_ANALYTICAL_REPORT_DATA = 'SET_ANALYTICAL_REPORT_DATA'
export const SET_ANALYTICAL_REPORT_DETAILS = 'SET_ANALYTICAL_REPORT_DETAILS'
export const SET_ACTIVITY_RATING_DATA = 'SET_ACTIVITY_RATING_DATA'
export const ADD_ACTIVITY_RATING_DATA = 'ADD_ACTIVITY_RATING_DATA'
export const SET_REPORT_LOADING = 'SET_REPORT_LOADING'
export const SET_REPORT_DETAILS_LOADING = 'SET_REPORT_DETAILS_LOADING'
export const SET_DEPARTMENT_CONTACTS = 'SET_DEPARTMENT_CONTACTS'
export const SET_POPULATION_REPORT_DATA = 'SET_POPULATION_REPORT_DATA'

export const GET_ABOUT_UNARMAINS = 'GET_ABOUT_UNARMAINS'
export const GET_ABOUT_UNARMAINS_LOADING = 'GET_ABOUT_UNARMAINS_LOADING'

export const SET_REGULATION_COUNT = 'SET_REGULATION_COUNT'
export const SET_REGULATION_DATA = 'SET_REGULATION_DATA'
export const SET_REGULATION_ARTICLES = 'SET_REGULATION_ARTICLES'
export const SET_REGULATION_SEARCH = 'SET_REGULATION_SEARCH'
export const SET_REGULATION_USER_LIST = 'SET_REGULATION_USER_LIST'
export const SET_REGIONS_LIST_REGULATION = 'SET_REGIONS_LIST_REGULATION'

export const SET_YUNARMY_HOUSES_COUNT = 'SET_YUNARMY_HOUSES_COUNT'
export const SET_YUNARMY_HOUSES_DATA = 'SET_YUNARMY_HOUSES_DATA'
export const SET_CURRENT_HOUSE_DATA = 'SET_CURRENT_HOUSE_DATA'
export const SET_YUNARMY_HOUSES_LOADING = 'SET_YUNARMY_HOUSES_LOADING'

export const SET_INFO_FOR_EXCEL= 'SET_INFO_FOR_EXCEL'
export const INFO_FOR_EXCEL_LOADING= 'INFO_FOR_EXCEL_LOADING'


export const SET_REGS = 'SET_REGS'

export const SET_STREAMS_LIST = 'SET_STREAMS_LIST'
export const SET_STREAMS_BY_ID = 'SET_STREAMS_BY_ID'
export const STREAM_LOADING = 'STREAM_LOADING'
export const SET_STREAMS_COUNT = 'SET_STREAMS_COUNT'

export const GET_EXCEL = 'GET_EXCEL'
export const GET_EXCEL_LOADING = 'GET_EXCEL_LOADING'

export const REMOVE_DETACHMENTS = 'REMOVE_DETACHMENTS'

export const SET_COUNTRY = 'SET_COUNTRY'
export const SET_FILTERED_COUNTRY = 'SET_FILTERED_COUNTRY'
export const REMOVE_COUNTRY_FILTER = 'REMOVE_COUNTRY_FILTER'

export const SET_BADGES_DATA = 'SET_BADGES_DATA'
export const SET_BADGES_IMAGES = 'SET_BADGES_IMAGES'
export const SET_BADGES_LOADING = 'SET_BADGES_LOADING'


export const SET_HERO = 'SET_HERO'
export const SET_HERO_COUNT = 'SET_HERO_COUNT'
export const SET_HERO_LOADING = 'SET_HERO_LOADING'
export const SET_HERO_DATA_BY_ID = 'SET_HERO_DATA_BY_ID'
export const GET_TROOPS = 'GET_TROOPS'
export const SET_HERO_IMAGE = 'SET_HERO_IMAGE'
export const REMOVE_HORE_IMAGE = 'REMOVE_HORE_IMAGE'

export const SET_PERMISSION_LOADING = 'SET_PERMISSION_LOADING'
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS'
export const SET_PERMISSION_GROUPS = 'SET_PERMISSION_GROUPS'
export const SET_PERMISSION_GROUP_INFO = 'SET_PERMISSION_GROUP_INFO'
export const SET_PERMISSION_GROUP_USERS = 'SET_PERMISSION_GROUP_USERS'

export const SET_ACCESS_ENTITIES = 'SET_ACCESS_ENTITIES'
export const SET_GROUP_ACCESS = 'SET_GROUP_ACCESS'
export const SET_ACCESS_LOADING = 'SET_CHAPTERS_LOADING'

export const SET_CHIEFS_SCHEDULE_LOADING = 'SET_CHIEFS_SCHEDULE_LOADING'
export const SET_CHIEFS_SCHEDULE_DATA = 'SET_CHIEFS_SCHEDULE_DATA'
export const SET_CHIEFS_SCHEDULE_EVENT = 'SET_CHIEFS_SCHEDULE_EVENT'

export const SET_REGION_RATING_CRITERION_BY_YEAR = 'SET_REGION_RATING_CRITERION_BY_YEAR'

export const  SET_NUMBER_REPORT = 'SET_NUMBER_REPORT'
export const  SET_NUMBER_REPORT_LOADING = 'SET_NUMBER_REPORT_LOADING'



import React, {useState, useEffect} from 'react'
import styles from "./Confirm.module.css";
import label from "../../assets/images/label.png";

const Confirm = () => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    let val = window.location.search.replace( '?status=', ''); 
    setStatus(val);  
  }, [])

  const messageList = (status) => {
    switch (status) {
      case '40':
          return 'Токен не подошел, возможно, ссылка была повреждена.'
      case '41':
          return 'Email не найден. Необходимо пройти первичную регистрацию и подтвердить указанный email.'
      case '42':
          return 'Время для подтверждения почты истекло, запросите ссылку.'
      case '43':
          return 'Неудачная попытка подтвердить email.'
      case '44':
          return 'Email уже подтвержден.'
      case '20':
        return 'Email успешно подтвержден.'
      
        default:
        break;
    }
  }

  const getMobileOperatingSystem = function () {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "https://www.rustore.ru/catalog/app/com.nintegra.yunarmy";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "yunarmy://";
    } else {
      return "https://lk.yunarmy.ru";
    }
  
  }         


  return (
    <div className={styles.wrapper}>
        <div className={styles.confirmWrapper}>
            <img src={label} className={styles.confirmImg}/>
            <div className={styles.confirmTxt}>{status === '20' ? 'Успешно!' : 'Ошибка!'}</div>
            <div className={styles.confirmAbout}>{messageList(status)}</div>

            <a 
                className={styles.confirmButton}
                href={getMobileOperatingSystem()}
            >
                <div className="fa fa-instagram" aria-hidden="true"> </div> Перейти в приложение

            </a>
        </div>
    </div>
  )
}

export default Confirm